import axios from "axios";
import { apiUrl } from "../../app/url";

export const hipnozaUpload = async (data, options) => {
  try {
    await axios.post(apiUrl + "/hipnoze", data, options);
  } catch (error) {
    throw error;
  }
};

export const hipnozaEdit = async (data, options) => {
  try {
    await axios.patch(apiUrl + "/hipnoze", data, options);
  } catch (error) {
    throw error;
  }
};

export const getHipnoze = async (id, config) => {
  try {
    const { data } = await axios.get(apiUrl + `/hipnoze/${id}`, config);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteHipnoza = async (id, catId, headers) => {
  try {
    await axios.delete(apiUrl + `/hipnoze/${id}/${catId}`, headers);
  } catch (error) {
    throw error;
  }
};
