import React, { useEffect, useState, useRef } from "react";
import { ListMusic, Plus, Calendar, Music, Upload } from "lucide-react";
import { getHipnoze, deleteHipnoza, hipnozaUpload, hipnozaEdit } from "./api";
import { getCatalog } from "../tipuri/api";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { Button, Input, message, Modal, Progress, Card, Select } from "antd";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { apiUrl } from "../../app/url";
import "./table.css";
import { getFormattedDate } from "../../hooks/date";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const HipnozeList = () => {
  const { id } = useAuth();
  const token = useSelector((state) => state.auth.token);
  const { TextArea } = Input;
  const [hipnoze, setHipnoze] = useState([]);
  const [categorii, setCategorii] = useState([]);
  const [songDialogOpen, setSongDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [singleFile, setSingleFile] = useState(null);
  const [mp3File, setMp3File] = useState(null);
  const [singleProgress, setSingleProgress] = useState(0);

  const [numeHipnoza, setNumeHipnoza] = useState(null);
  const [descriereaHipnoza, setDescriereaHipnoza] = useState(null);
  const [unlockPeriod, setUnlockPeriod] = useState(0);
  const [picture, setPicture] = useState(null);
  const [idHipnoza, setIdHipnoza] = useState(null);
  const [idCatalog, setIdCatalog] = useState(null);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  function getCategorie(code) {
    let found = categorii?.find((e) => e._id === code);
    if (categorii.length !== 0) return found.categorie;
  }

  async function fetchDataHipnoze() {
    try {
      const fileslist = await getHipnoze(id, config);
      setHipnoze(fileslist);
    } catch (error) {
      setHipnoze([]);
    }
  }

  async function fetchDataCategorii() {
    try {
      const fileslist = await getCatalog(config);
      setCategorii(fileslist);
    } catch (error) {
      setCategorii([]);
    }
  }

  function resetAll() {
    setNumeHipnoza(null);
    setDescriereaHipnoza(null);
    setUnlockPeriod(0);
    setPicture(null);
    setSingleProgress(0);
    setIdHipnoza(null);
    setIdCatalog(null);
    setSongDialogOpen(false);
  }

  useEffect(() => {
    fetchDataHipnoze();
    fetchDataCategorii();
  }, []);

  const handleChangeCatalog = (value) => {
    setIdCatalog(value);
  };

  ////////////////////////////////////////////////////////////////

  const twoColors = {
    "0%": "#108ee9",
    "100%": "#87d068",
  };

  const fileInputRefPic = useRef(null);
  const fileInputRefMp3 = useRef(null);

  const SingleFileChange = (e) => {
    setSingleFile(e.target.files[0]);
    setSingleProgress(0);
  };

  const Mp3FileChange = (e) => {
    setMp3File(e.target.files[0]);
    setSingleProgress(0);
  };

  const singleFileOptions = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
      setSingleProgress(percentage);
    },
    headers: { Authorization: `Bearer ${token}` },
  };

  const uploadSingleFile = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("categorieID", idCatalog);
      formData.append("tipuri", "hipnoza");
      formData.append("numeHipnoza", numeHipnoza);
      formData.append("descriereHipnoza", descriereaHipnoza);
      formData.append("unlockPeriod", unlockPeriod);
      formData.append("files", mp3File);
      formData.append("files", singleFile);
      if (idHipnoza !== null) {
        //aici cand o modificam
        formData.append("id", idHipnoza);
        await hipnozaEdit(formData, singleFileOptions);
        message.success("Hipnoza a fost modificata cu succes!");
      } else {
        if (!singleFile) {
          return message.error("Incarca o fotografie");
        }
        if (!mp3File) {
          return message.error("Incarca un fisier audio");
        }
        //aici este cand o cream
        await hipnozaUpload(formData, singleFileOptions);
        message.success("Hipnoza a fost creata cu succes!");
      }

      fetchDataHipnoze();

      setSingleFile(null);
      setMp3File(null);

      resetAll();
    } catch (error) {
      message.error("Eroare la crearea hipnozei: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSong = async (id, catId) => {
    await deleteHipnoza(id, catId, config);
    fetchDataHipnoze();
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: "rgba(39, 39, 42, 0.5)",
          border: "1px solid rgba(63, 63, 70, 0.5)",
          color: "white",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <ListMusic
                  style={{
                    height: "1.25rem",
                    width: "1.25rem",
                    color: "#10B981",
                  }}
                />
                Hipnoze
              </div>
              <div>Gestionarea hipnozelor</div>
            </div>
            <Button onClick={() => setSongDialogOpen(true)}>
              <Plus />
              Adauga hipnoza
            </Button>
          </div>
        </div>

        <div>
          {hipnoze.length !== 0 ? (
            <Table>
              <TableHeader>
                <TableRow className="tableRow">
                  <TableHead style={{ width: "50px" }}></TableHead>
                  <TableHead>Hipnoza</TableHead>
                  <TableHead>Categorie</TableHead>
                  <TableHead>Descriere</TableHead>
                  <TableHead>Data adaugarii</TableHead>
                  <TableHead style={{ textAlign: "right" }}>Actiuni</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {hipnoze?.map((song) => (
                  <TableRow key={song._id} className="tableRow">
                    <TableCell>
                      <img
                        src={apiUrl + "/" + song.profilePicture}
                        alt={song.hipnoza}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </TableCell>
                    <TableCell className="tableCell1">
                      {song.numeHipnoza}
                    </TableCell>
                    <TableCell>
                      <span className="tableCell2">
                        {getCategorie(song.categorieID)}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="tableCell2">
                        {song.descriereHipnoza.split("\n")[0]} ...
                      </span>
                    </TableCell>

                    <TableCell>
                      <span className="tableCell2">
                        <Calendar className="icons" />
                        {getFormattedDate(song.createdAt)}
                      </span>
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <div className="btnsActions">
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => {
                            setNumeHipnoza(song.numeHipnoza);
                            setDescriereaHipnoza(song.descriereHipnoza);
                            setUnlockPeriod(song.unlockPeriod);
                            setPicture(song.profilePicture);
                            setIdHipnoza(song._id);
                            setIdCatalog(song.categorieID);
                            setSongDialogOpen(true);
                          }}
                        />

                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => deleteSong(song._id, song.categorieID)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
                fontWeight: 500,
                fontSize: "1rem",
              }}
            >
              Nu exista nicio hipnoza adaugata!
            </div>
          )}
        </div>
      </Card>
      <Modal
        onCancel={() => {
          resetAll();
        }}
        open={songDialogOpen}
        centered
        footer={false}
        styles={{
          content: {
            backgroundColor: "#18181B",
            color: "white",
            border: "1px solid #3F3F46",
          },
          header: {},
          body: {},
        }}
      >
        <div>
          {idHipnoza !== null ? "Modifica hipnoza" : "Adauga o hipnoza "}

          <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
            <input
              type="file"
              ref={fileInputRefPic}
              onChange={(e) => SingleFileChange(e)}
              accept="image/png, image/jpeg, image/jpg"
              style={{ display: "none" }}
            />
            <input
              type="file"
              ref={fileInputRefMp3}
              onChange={(e) => Mp3FileChange(e)}
              accept="audio/mpeg, audio/mp3"
              style={{ display: "none" }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1.5rem",
                border: "2px dashed #3F3F46",
                borderRadius: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => fileInputRefPic.current?.click()}
            >
              <div style={{ textAlign: "center" }}>
                {picture ? (
                  <>
                    <img
                      src={apiUrl + "/" + picture}
                      alt={numeHipnoza}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "4px",
                        objectFit: "cover",
                      }}
                    />
                    <div
                      style={{
                        fontSize: "0.875rem",
                        color: "#A1A1AA",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {singleFile
                        ? "Fisierul selectat: " + singleFile.name
                        : "Modifica poza de profil"}
                    </div>

                    <Button style={{ fontSize: "0.75rem" }}>
                      Alege un alt fisier
                    </Button>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        padding: "0.75rem",
                        backgroundColor: "#27272A",
                        borderRadius: "9999px",
                        display: "inline-block",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <Upload
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          color: "#A1A1AA",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "0.875rem",
                        color: "#A1A1AA",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {singleFile
                        ? "Fisierul selectat: " + singleFile.name
                        : "Incarca o poza de profil"}
                    </div>

                    <Button style={{ fontSize: "0.75rem" }}>
                      Alege un fisier
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1.5rem",
                border: "2px dashed #3F3F46",
                borderRadius: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => fileInputRefMp3.current?.click()}
            >
              <div style={{ textAlign: "center" }}>
                {picture ? (
                  <>
                    <div
                      style={{
                        fontSize: "0.875rem",
                        color: "#A1A1AA",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {mp3File
                        ? "Fisierul selectat: " + mp3File.name
                        : "Modifica fisierul audio"}
                    </div>

                    <Button style={{ fontSize: "0.75rem" }}>
                      Alege un alt fisier
                    </Button>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        padding: "0.75rem",
                        backgroundColor: "#27272A",
                        borderRadius: "9999px",
                        display: "inline-block",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <Upload
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          color: "#A1A1AA",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "0.875rem",
                        color: "#A1A1AA",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {mp3File
                        ? "Fisierul selectat: " + mp3File.name
                        : "Incarca o hipnoza"}
                    </div>

                    <Button style={{ fontSize: "0.75rem" }}>
                      Alege un fisier
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div style={{ marginTop: "1rem" }}>
              <Select
                value={
                  idCatalog ? getCategorie(idCatalog) : "Selecteaza categoria"
                }
                style={{
                  width: "100%",
                }}
                onChange={handleChangeCatalog}
                options={categorii.map((e) => {
                  return {
                    value: e._id,
                    label: e.categorie,
                  };
                })}
                placeholder="Selecteaza categoria"
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <Input
                type="text"
                value={numeHipnoza}
                onChange={(e) => setNumeHipnoza(e.target.value)}
                placeholder="Numele hipnozei"
                className="inputSongs"
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <TextArea
                value={descriereaHipnoza}
                onChange={(e) => setDescriereaHipnoza(e.target.value)}
                placeholder="Descrierea hipnozei"
                className="inputSongs"
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <Input
                type="number"
                value={unlockPeriod}
                onChange={(e) => setUnlockPeriod(e.target.value)}
                prefix="Perioada de deblocare:"
                suffix="Zile"
                className="inputSongs"
              />
            </div>

            {isLoading && (
              <div
                style={{
                  marginTop: "0.75rem",
                }}
              >
                <Progress
                  percentPosition={{
                    align: "center",
                    type: "inner",
                  }}
                  percent={singleProgress}
                  strokeColor={twoColors}
                  trailColor={"white"}
                  size={{ width: "100%", height: 20 }}
                />
              </div>
            )}

            <Button
              onClick={uploadSingleFile}
              className="butonSongs"
              disabled={isLoading || !numeHipnoza || !idCatalog}
            >
              {isLoading
                ? "Se salveaza datele..."
                : `${
                    idHipnoza !== null ? "Salveaza hipnoza" : "Adauga hipnoza"
                  }`}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default HipnozeList;
