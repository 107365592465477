import React from "react";

function Campanii() {
  return (
    <>
      <div>Campanii</div>
    </>
  );
}

export default Campanii;
