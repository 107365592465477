import React, { useEffect, useState, useRef } from "react";
import { Library, Plus, Calendar, Music, Upload } from "lucide-react";
import { getCatalog, deleteCatalog, catalogUpload, catalogEdit } from "./api";
import { useSelector } from "react-redux";
import { Button, Input, message, Modal, Progress, Card } from "antd";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { apiUrl } from "../../app/url";
import "./table.css";
import { getFormattedDate } from "../../hooks/date";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

const CategorieList = () => {
  const token = useSelector((state) => state.auth.token);
  const [catalogs, setCatalogs] = useState([]);
  const [albumDialogOpen, setAlbumDialogOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [singleFile, setSingleFile] = useState(null);
  const [singleProgress, setSingleProgress] = useState(0);

  const [categorie, setCategorie] = useState(null);
  const [pret, setPret] = useState(null);
  const [picture, setPicture] = useState(null);
  const [idCategorie, setIdCategorie] = useState(null);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  async function fetchData() {
    try {
      const fileslist = await getCatalog(config);
      setCatalogs(fileslist);
    } catch (error) {
      setCatalogs([]);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  ////////////////////////////////////////////////////////////////

  const twoColors = {
    "0%": "#108ee9",
    "100%": "#87d068",
  };

  const fileInputRef = useRef(null);

  const SingleFileChange = (e) => {
    setSingleFile(e.target.files[0]);
    setSingleProgress(0);
  };

  const singleFileOptions = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percentage = Math.floor(((loaded / 1000) * 100) / (total / 1000));
      setSingleProgress(percentage);
    },
    headers: { Authorization: `Bearer ${token}` },
  };

  const uploadSingleFile = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("tipuri", "categorie");
      formData.append("categorie", categorie);
      formData.append("pret", pret);
      formData.append("file", singleFile);
      if (idCategorie !== null) {
        //aici cand o modificam
        formData.append("id", idCategorie);
        await catalogEdit(formData, singleFileOptions);
        message.success("Categoria a fost modificata cu succes!");
      } else {
        if (!singleFile) {
          return message.error("Incarca o fotografie");
        }
        //aici este cand o cream
        await catalogUpload(formData, singleFileOptions);
        message.success("Categoria a fost creata cu succes!");
      }

      fetchData();

      setCategorie(null);
      setPret(null);
      setPicture(null);
      setSingleProgress(0);
      setSingleFile(null);
      setAlbumDialogOpen(false);
    } catch (error) {
      message.error("Eroare la crearea categoriei: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteAlbum = async (id) => {
    await deleteCatalog(id, config);
    fetchData();
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: "rgba(39, 39, 42, 0.5)",
          border: "1px solid rgba(63, 63, 70, 0.5)",
          color: "white",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                <Library
                  style={{
                    height: "1.25rem",
                    width: "1.25rem",
                    color: "#8B5CF6",
                  }}
                />
                Categorii de hipnoze
              </div>
              <div>Gestionarea categoriilor de hipnoze</div>
            </div>
            <Button onClick={() => setAlbumDialogOpen(true)}>
              <Plus />
              Adauga categorie
            </Button>
          </div>
        </div>

        <div>
          {catalogs.length !== 0 ? (
            <Table>
              <TableHeader>
                <TableRow className="tableRow">
                  <TableHead style={{ width: "50px" }}></TableHead>
                  <TableHead>Categorie</TableHead>
                  <TableHead>Data adaugarii</TableHead>
                  <TableHead>Hipnoze</TableHead>
                  <TableHead style={{ textAlign: "right" }}>Actiuni</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {catalogs?.map((album) => (
                  <TableRow key={album._id} className="tableRow">
                    <TableCell>
                      <img
                        src={apiUrl + "/" + album.profilePicture}
                        alt={album.categorie}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "4px",
                          objectFit: "cover",
                        }}
                      />
                    </TableCell>
                    <TableCell className="tableCell1">
                      {album.categorie}
                    </TableCell>
                    <TableCell>
                      <span className="tableCell2">
                        <Calendar className="icons" />
                        {getFormattedDate(album.createdAt)}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="tableCell2">
                        <Music className="icons" />
                        {album.hipnoze} hipnoze
                      </span>
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <div className="btnsActions">
                        <Button
                          icon={<EditOutlined />}
                          onClick={() => {
                            setCategorie(album.categorie);
                            setPret(album.pret);
                            setPicture(album.profilePicture);
                            setIdCategorie(album._id);
                            setAlbumDialogOpen(true);
                          }}
                        />

                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => deleteAlbum(album._id)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
                fontWeight: 500,
                fontSize: "1rem",
              }}
            >
              Nu exista nicio categorie de hipnoze!
            </div>
          )}
        </div>
      </Card>
      <Modal
        onCancel={() => {
          setCategorie(null);
          setPret(null);
          setPicture(null);
          setSingleProgress(0);
          setIdCategorie(null);
          setAlbumDialogOpen(false);
        }}
        open={albumDialogOpen}
        centered
        footer={false}
        styles={{
          content: {
            backgroundColor: "#18181B",
            color: "white",
            border: "1px solid #3F3F46",
          },
          header: {},
          body: {},
        }}
      >
        <div>
          {categorie !== null
            ? "Modifica categoria de hipnoze"
            : "Adauga o categorie de hipnoze"}

          <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(e) => SingleFileChange(e)}
              accept="image/png, image/jpeg, image/jpg"
              style={{ display: "none" }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "1.5rem",
                border: "2px dashed #3F3F46",
                borderRadius: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => fileInputRef.current?.click()}
            >
              <div style={{ textAlign: "center" }}>
                {picture ? (
                  <>
                    <img
                      src={apiUrl + "/" + picture}
                      alt={categorie}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "4px",
                        objectFit: "cover",
                      }}
                    />
                    <div
                      style={{
                        fontSize: "0.875rem",
                        color: "#A1A1AA",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {singleFile
                        ? "Fisierul selectat: " + singleFile.name
                        : "Modifica o poza de profil pentru acesta categorie"}
                    </div>

                    <Button style={{ fontSize: "0.75rem" }}>
                      Alege un alt fisier
                    </Button>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        padding: "0.75rem",
                        backgroundColor: "#27272A",
                        borderRadius: "9999px",
                        display: "inline-block",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <Upload
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          color: "#A1A1AA",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        fontSize: "0.875rem",
                        color: "#A1A1AA",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {singleFile
                        ? "Fisierul selectat: " + singleFile.name
                        : "Incarca o poza de profil"}
                    </div>

                    <Button style={{ fontSize: "0.75rem" }}>
                      Alege un fisier
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div style={{ marginTop: "1rem" }}>
              <Input
                type="text"
                value={categorie}
                onChange={(e) => setCategorie(e.target.value)}
                placeholder="Numele categoriei"
                className="inputSongs"
              />
            </div>

            <div style={{ marginTop: "1rem" }}>
              <Input
                type="text"
                value={pret}
                onChange={(e) => setPret(e.target.value)}
                placeholder="Pretul pentru abonament"
                className="inputSongs"
              />
            </div>

            {isLoading && (
              <div
                style={{
                  marginTop: "0.75rem",
                }}
              >
                <Progress
                  percentPosition={{
                    align: "center",
                    type: "inner",
                  }}
                  percent={singleProgress}
                  strokeColor={twoColors}
                  trailColor={"white"}
                  size={{ width: "100%", height: 20 }}
                />
              </div>
            )}

            <Button
              onClick={uploadSingleFile}
              className="butonSongs"
              disabled={isLoading || !categorie}
            >
              {isLoading
                ? "Se salveaza datele..."
                : `${
                    categorie !== null
                      ? "Salveaza categoria"
                      : "Adauga categoria"
                  }`}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CategorieList;
