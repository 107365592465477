// import { useMusicStore } from "@/stores/useMusicStore";
import { Library, ListMusic, PlayCircle, Users2 } from "lucide-react";
import StatsCard from "./StatsCard";
import styles from "./DashboardStats.module.css";

const DashboardStats = () => {
  // const { stats } = useMusicStore();

  const statsData = [
    {
      icon: Library,
      label: "Categorii de hipnoze",
      value: 2, //stats.totalSongs.toString(),
      bgColor: "rgba(139, 92, 246, 0.1)",
      iconColor: "#8B5CF6",
      link: "/tipuri",
    },
    {
      icon: ListMusic,
      label: "Hipnoze",
      value: 15, //stats.totalAlbums.toString(),
      bgColor: "rgba(16, 185, 129, 0.1)",
      iconColor: "#10B981",
      link: "/hipnoza",
    },
    {
      icon: Users2,
      label: "Utilizatori",
      value: 3, //stats.totalArtists.toString(),
      bgColor: "rgba(255, 159, 31, 0.1)",
      iconColor: "#FB923C",
      link: "/utilizatori",
    },
    {
      icon: PlayCircle,
      label: "Campanii",
      value: 7, //stats.totalUsers.toLocaleString(),
      bgColor: "rgba(14, 165, 233, 0.1)",
      iconColor: "#0EA5E9",
      link: "/campanii",
    },
  ];

  return (
    <div className={styles.dashboard}>
      {statsData.map((stat) => (
        <StatsCard
          key={stat.label}
          icon={stat.icon}
          label={stat.label}
          value={stat.value}
          bgColor={stat.bgColor}
          iconColor={stat.iconColor}
          link={stat.link}
        />
      ))}
    </div>
  );
};
export default DashboardStats;
