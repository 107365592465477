import * as React from "react";
import { cn } from "./utils";
import "./Table.css"; // Importă stilurile personalizate

// Componenta Table
const Table = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <div className="table-container">
      <table
        ref={ref}
        className={`custom-table ${className || ""}`} // Adaugă clase externe dacă există
        {...otherProps}
      />
    </div>
  );
});
Table.displayName = "Table";

export default Table;

// TableHeader
const TableHeader = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <thead
      ref={ref}
      className={`table-header ${className || ""}`}
      {...otherProps}
    />
  );
});
TableHeader.displayName = "TableHeader";

// TableBody
const TableBody = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <tbody
      ref={ref}
      className={`table-body ${className || ""}`}
      {...otherProps}
    />
  );
});
TableBody.displayName = "TableBody";

// TableFooter
const TableFooter = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <tfoot
      ref={ref}
      className={`table-footer ${className || ""}`}
      {...otherProps}
    />
  );
});
TableFooter.displayName = "TableFooter";

// TableRow
const TableRow = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <tr ref={ref} className={`table-row ${className || ""}`} {...otherProps} />
  );
});
TableRow.displayName = "TableRow";

// TableHead
const TableHead = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <th ref={ref} className={`table-head ${className || ""}`} {...otherProps} />
  );
});
TableHead.displayName = "TableHead";

// TableCell
const TableCell = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <td ref={ref} className={`table-cell ${className || ""}`} {...otherProps} />
  );
});
TableCell.displayName = "TableCell";

// TableCaption
const TableCaption = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props;

  return (
    <caption
      ref={ref}
      className={`table-caption ${className || ""}`}
      {...otherProps}
    />
  );
});
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
