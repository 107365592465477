import { Link } from "react-router-dom";
import logo from "../../../resourses/logo.png";
import { PoweroffOutlined } from "@ant-design/icons";
import { useSendLogoutMutation } from "../../auth/authApiSlice";

const Header = () => {
  const [sendLogout] = useSendLogoutMutation();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.75rem",
          marginBottom: "2rem",
        }}
      >
        <Link to="/" style={{ borderRadius: "0.5rem" }}>
          <img
            src={logo}
            alt="logo"
            style={{
              fontSize: "2.5rem",
              color: "#000000",
              width: 120,
              height: 60,
            }}
          />
        </Link>
        <div>
          <h1 style={{ fontSize: "1.875rem", fontWeight: "700" }}>
            Admin Dependent.Ro
          </h1>
          <p style={{ color: "#a1a1aa", marginTop: "0.25rem" }}>
            Catalog hipnoze
          </p>
        </div>
      </div>
      <PoweroffOutlined
        style={{
          color: "red",
          fontSize: "2rem",
          cursor: "pointer",
          marginBottom: "2rem",
        }}
        onClick={sendLogout}
      />
    </div>
  );
};
export default Header;
