import React from "react";
import "./App.css";
import DefaultLayout from "./components/DefaultLayout";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./features/auth/Login";
import Register from "./features/auth/Register";

import Homepage from "./features/auth/Homepage";

import Campanii from "./pages/Campanii";
import Hipnoze from "./pages/Hipnoze";
import Tipuri from "./pages/Tipuri";
import Utilizatori from "./pages/Utilizatori";

import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import useTitle from "./hooks/useTitle";

function App() {
  useTitle("dependent.ro");

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />

          {/* Protected Routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route element={<Prefetch />}>
                <Route path="/" element={<DefaultLayout />}>
                  <Route index element={<Homepage />} />

                  <Route path="hipnoza">
                    <Route index element={<Hipnoze />} />
                  </Route>
                  <Route path="tipuri">
                    <Route index element={<Tipuri />} />
                  </Route>
                  <Route path="campanii">
                    <Route index element={<Campanii />} />
                  </Route>

                  <Route path="utilizatori">
                    <Route index element={<Utilizatori />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* End Protected Routes */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
