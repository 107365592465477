import axios from "axios";
import { apiUrl } from "../../app/url";

export const catalogUpload = async (dataForm, options) => {
  try {
    await axios.post(apiUrl + "/catalog", dataForm, options);
  } catch (error) {
    throw error;
  }
};

export const catalogEdit = async (data, options) => {
  try {
    await axios.patch(apiUrl + "/catalog", data, options);
  } catch (error) {
    throw error;
  }
};

export const getCatalog = async (config) => {
  try {
    const { data } = await axios.get(apiUrl + `/catalog`, config);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteCatalog = async (id, headers) => {
  try {
    await axios.delete(apiUrl + `/catalog/${id}`, headers);
  } catch (error) {
    throw error;
  }
};
