import React from "react";
import CategorieList from "../features/tipuri/CategorieList";

function Tipuri() {
  return (
    <>
      <CategorieList />
    </>
  );
}

export default Tipuri;
