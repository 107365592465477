import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";
import styles from "./StatsCard.module.css";

const StatsCard = ({ bgColor, icon: Icon, iconColor, label, value, link }) => {
  const navigate = useNavigate();
  return (
    <Card
      className={styles.card}
      onClick={() => {
        navigate(link);
      }}
    >
      <div className={styles.cardDiv}>
        <div
          style={{
            padding: "0.75rem",
            borderRadius: "0.5rem",
            backgroundColor: bgColor,
          }}
        >
          <Icon style={{ fontSize: "1.5rem", color: iconColor }} />
        </div>
        <div>
          <p className={styles.labelParagraf}>{label}</p>
          <p className={styles.valueParagraf}>{value}</p>
        </div>
      </div>
    </Card>
  );
};
export default StatsCard;
