import { Outlet } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { useSendLogoutMutation } from "../features/auth/authApiSlice";
import useAuth from "../hooks/useAuth";
import Header from "../features/admin/components/Header";
import DashboardStats from "../features/admin/components/DashboardStats";
import "./DefaultLayout.css";

const { Content } = Layout;

function DefaultLayout() {
  const { isAdmin, isLoading, id, active } = useAuth();

  const [sendLogout] = useSendLogoutMutation();

  return (
    <Layout className="main">
      <Header />

      <DashboardStats />

      <Content>
        <div
          style={{
            overflowY: "scroll",
            height: "100%",
            color: "white",
          }}
        >
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
}

export default DefaultLayout;
